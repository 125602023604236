import logo from "./logo.webp"
const  clientData = {
   client_entity: 19,
   attorney_firm: 'gravislaw.com',
    attorney_name: 'Kevin Chern',
    attorney_number: '312-654-6644',
    attorney_email: 'ERTC@GravisLaw.com',
    client_video_source: 'https://erc-videos.s3.amazonaws.com/ertc_explainer_-_gravis_law+(720p).mp4',
    mainColor: 'white',
    secondaryColor: '#f49231',
    bgColor:'#4b5b79',
    siteLink: 'http://gravislaw.com/',
    logo
}

export default clientData